import React from 'react';
import { createContext, useEffect, useState } from 'react';
import { masterDataUrl } from '../constants/config';
export const masterDataContext = createContext({
  masterData: null,
});
export const MasterDataContextProvider = ({ children }) => {
  const [masterData, setMasterData] = useState(null);
  useEffect(() => {
    const getMasterData = async () => {
      fetch(masterDataUrl)
        .then(res => res.json())
        .then(res => {
          setMasterData(res.Data);
        })
        .catch(err => {
          console.log(err);
        });
    };
    if (typeof window !== 'undefined') {
      getMasterData();
    }
  }, []);
  return (
    <masterDataContext.Provider
      value={{
        masterData,
      }}
    >
      {children}
    </masterDataContext.Provider>
  );
};
MasterDataContextProvider.propTypes = {
  children: React.ReactNode,
};
