import React from 'react';
// import ReactDOM from 'react-dom';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import 'bootstrap/dist/css/bootstrap.css';
import 'core-js/modules/es6.array.from';
import { MasterDataContextProvider } from 'store/masterDataContext';
// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <MasterDataContextProvider>{element}</MasterDataContextProvider>
    </AppProvider>
  );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

export const onRouteUpdate = win => {
  if (win.location && win.location.hash && window.location.hash !== '') {
    setTimeout(() => {
      console.log(
        'onRouteUpdate',
        document.querySelector(`${win.location.hash}`)
      );
      if (document.querySelector(`${win.location.hash}`)) {
        document.querySelector(`${win.location.hash}`).scrollIntoView(true);

        if (typeof window !== 'undefined') {
          window.scrollBy(0, -120);
        }
      }
    }, 1000);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
};
