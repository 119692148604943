export const reCaptchaKey = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.reCaptchaKey
    ? window.manifest.reCaptchaKey
    : '6LcpXd4UAAAAABG7ilvQgQAmobLvrJqn-4VaupNC';

export const gMapApiKey = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.gMapApiKey
    ? window.manifest.gMapApiKey
    : 'AIzaSyAFq1fItucvNN8W751EX2dHcankbZcdFdA';

export const tenantId = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.tenantId
    ? window.manifest.tenantId
    : 90327182;

export const baseUrl = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com';

export const masterDataUrl = `${baseUrl()}/api/study/codes/all?tenantId=${tenantId()}`;

export const geographicalCoordinatesUrl = (country, postalCode, city, region) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  `/api/GetLatLon?tenantId=${tenantId()}&country=${country}&postalCode=${postalCode}${
    city ? `&city=${city}` : ''
  }${region ? `&region=${region}` : ''}`;

export const searchTrialUrl = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/search?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const submitFormUrl = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/contact-us/submit?tenantId=' +
  tenantId();

export const allTrialsIds = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/ids/all?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const trialDetailsUrl = (studyId, typeOfId) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/?tenantId=' +
  tenantId() +
  // typeOfId is used to determine if is an old internal Id or is a customer unique Id
  `${typeOfId === 'old' ? '&Id=' : '&uniqueStudyId='}` +
  studyId +
  '&_cache=' +
  new Date();

export const fileUploadMaxSize = () =>
  ((typeof window !== 'undefined' &&
    window.manifest &&
    window.manifest.upload_max_file_mb) ||
    1) * 1048576; // 1048576 = 1MB
