import React from 'react';
import PropTypes from 'prop-types';

const Transition = ({ children }) => {
  return <div>{children}</div>;
};

Transition.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default Transition;
